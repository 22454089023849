export const setCookie = (cname: string, cvalue: string, exdays: number) => {
  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  const expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
}

export const deleteCookie = (cname: string, domain?: string) => {
  const cookies = document.cookie.split('; ')
  const cookieToDelete = cookies.filter((cookie) => cookie.includes(cname))

  cookieToDelete.forEach((cookie) => {
    const cookieName = cookie.split('=')[0]
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${domain || '.syniva.es'};`
  })
}