import { useState, useEffect, useContext, createContext } from 'react'
import supabase from '@/lib/supabaseClient'
import { UserCredentials } from '@supabase/supabase-js'
import { User } from '@/types/supabase.types'
import { registerAccess } from '@/lib/supabaseApi'

const AuthContext = createContext<
  {
    user: User | null
    signUp: (data: UserCredentials) => Promise<any> // eslint-disable-line no-unused-vars
    signIn: (data: UserCredentials) => Promise<any> // eslint-disable-line no-unused-vars
    signOut: () => Promise<any>
  }
>({
  user: null,
  signUp: async (data: UserCredentials) => { }, // eslint-disable-line no-unused-vars
  signIn: async (data: UserCredentials) => { }, // eslint-disable-line no-unused-vars
  signOut: async () => { },

})

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<any>()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // Check active sessions and sets the user
    const session = supabase.auth.session()

    setUser(session?.user ?? null)
    setLoading(false)

    // Listen for changes on auth state (logged in, signed out, etc.)
    const { data: listener } = supabase.auth.onAuthStateChange(
      async (event, session) => {

        if (session?.user) registerAccess(event, session.user.id, session.user.email || null)

        setUser(session?.user ?? null)
        setLoading(false)
      }
    )

    return () => {
      listener?.unsubscribe()
    }
  }, [])

  const value = {
    signUp: (data: UserCredentials) => supabase.auth.signUp(data),
    signIn: (data: UserCredentials) => supabase.auth.signIn(data),
    signOut: () => supabase.auth.signOut(),
    user,
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  return useContext(AuthContext)
}
