export const VAR_SHIPPING_INSURANCE = 'SHIPPING_INSURANCE'
export const VAR_LOW_VALUE_LIMIT = 'LOW_VALUE_LIMIT'
export const SHIP_MODE_MIN_VALUE = 'SHIP_MODE_MIN_VALUE'
export const FREE_SHIPMENT_ECONOMY = 'FREE_SHIPMENT_ECONOMY'
export const FREE_SUPPLEMENTS_ECONOMY = 'FREE_SUPPLEMENTS_ECONOMY'
export const FREE_ECONOMY_PRICE_MIN = 'FREE_ECONOMY_PRICE_MIN'
export const DEFAULT_SHIPPING_METHOD_ID = 'DEFAULT_SHIPPING_METHOD_ID'
export const DEFAULT_SHIPMODE_SHIPPING_METHOD_ID = 'DEFAULT_SHIPMODE_SHIPPING_METHOD_ID'
export const MAX_KG_DEFAULT_SHIPPING_PRICE = 'MAX_KG_DEFAULT_SHIPPING_PRICE'
export const MAX_KG_EXPRESS_SHIPPING_PRICE = 'MAX_KG_EXPRESS_SHIPPING_PRICE'
export const CUSTOM_ORDER_MANAGEMENT_PRICE = 'CUSTOM_ORDER_MANAGEMENT_PRICE'
export const BLACKLIST_STORES = 'BLACKLIST_STORES'
export const DEPARTMENT_NAME = 'DEPARTAMENTOS'
export const VAR_BENEFIT_VALUE = 'BENEFIT_VALUE'
export const VAR_BENEFIT_MIN_ORDER = 'BENEFIT_MIN_ORDER'

export const MOBILE_WIDTH_LIMIT = 450
export const TABLET_WIDTH_LIMIT = 769
export const LOW_IGIC_VALUE = 150

export const CUSTOM_ORDER_MARKETPLACE_ID = 0

export const COLOR_LIGHT = '#d5d5d5'

export const PREFIX_ORDER = 'or'
export const PREFIX_SUPPLEMENT = 'sup'

export const BUSINESS_USER_ROLE = 5

export const ORDER_STATUS_ID_PENDING_PAYMENT = 0
export const ORDER_STATUS_ID_REQUESTED = 1
export const ORDER_STATUS_ID_PROCESSED = 2
export const ORDER_STATUS_ID_CANCELLED = 3
export const ORDER_STATUS_ID_RETURN = 4
export const ORDER_STATUS_ID_SUPPLEMENT = 5
export const ORDER_STATUS_ID_ADMITTED = 6
export const ORDER_STATUS_ID_NO_STOCK = 7
export const ORDER_STATUS_ID_PREPARED = 8
export const ORDER_STATUS_ID_SENT = 9
export const ORDER_STATUS_ID_DELIVERED = 10
export const ORDER_STATUS_ID_PENALIZATION = 11
export const ORDER_STATUS_ID_PARCIAL_RETURN = 13
export const ORDER_STATUS_ID_DECREASE_RETURN = 14
export const ORDER_STATUS_ID_INTERNAL_SPENDING_RETURN = 15

export const SHIPPING_METHOD_STANDARD_ID = 2
export const SHIPPING_METHOD_ECONOMY_ID = 4

export const PAYMENT_METHOD_CARD = 1

export const LEGAL_ADVISE = 'aviso-legal-syniva.pdf'
export const PRIVACY_POLICY = 'politica-de-privacidad-syniva.pdf'
export const COOKIES_POLICY = 'politica-de-cookies-syniva.pdf'
export const BUY_CONDITIONS = 'condiciones-de-compra-syniva.pdf'
export const RSC_CERT = 'certificado-rsc-syniva.pdf'

export const SYNIVA_EMAIL = 'hola@syniva.es'
export const SYNIVA_PHONE = '928564297'
export const SOCIAL_LINKS = {
    youtube: 'https://www.youtube.com/channel/UCBa9kJ71pvYbK_ZhZv8xYnw/featured',
    x: 'https://x.com/syniva_es',
    instagram: 'https://www.instagram.com/syniva_es/',
    facebook: 'https://www.facebook.com/syniva',
    tiktok: 'https://www.tiktok.com/@syniva.es',
    whatsapp: 'https://wa.me/34620226654'
}

export const IGIC_TYPE_GENERAL = 'general'
export const IGIC_TYPE_REDUCIDO = 'reducido'

export const CANCEL_OPTIONS = [
    { label: 'noWantIt', value: 'noWantIt' },
    { label: 'priceChange', value: 'priceChange' },
    { label: 'ivaChange', value: 'ivaChange' },
    { label: 'tariff', value: 'tariff' },
    { label: 'transportSup', value: 'transportSup' },
    { label: 'volumetricSup', value: 'volumetricSup' },
    { label: 'waitTime', value: 'waitTime' }
    // { label: 'noStock', value: 'noStock' }
]

export const RETURN_OPTIONS = [
    { label: 'badSize', value: 'badSize' },
    { label: 'noWantIt', value: 'noWantIt' },
    { label: 'badDescription', value: 'badDescription' },
    { label: 'noWork', value: 'noWork' },
    { label: 'badProduct', value: 'badProduct' }
]

export const SITES_DOMAINS_EXCLUDES = [
    'es',
    'ES',
    'au',
    'com',
    'COM',
    'checkout',
    'checkout1',
    'org',
    'eu',
    'EU',
    'buy',
    'gg',
    'secure',
    'pay',
    'net',
    'de',
    'co',
    'gr',
    'uk',
    'www',
    'www2',
    'Www',
    'WWW',
    'it',
    'io',
    'm',
    'store',
    'tienda',
    'estore',
    'outlet',
    'shop',
    'merch',
    'ch',
    'nl',
    'in',
    'fr',
    'cz',
    'tech',
    'dk',
    'pt',
    'pl',
    'page',
    'mobile',
    'spain',
    'Spain',
    'SPAIN'
]

export const BUSINESS_TEMPLATE_PATH = '/templates/business-template.xlsx'

// Estimated costs
export const ADVERTISEMENT_STATUS_ID = 1
export const SENT_BUDGE_STATUS_ID = 2
export const ACEPTED_STATUS_ID = 3
export const CANCELLED_STATUS_ID = 4
export const ADVERTISEMENT_STATUS_TEXT = 'Aviso enviado'
export const SENT_BUDGE_STATUS_TEXT = 'Enviado presupuesto'
export const ACEPTED_STATUS_TEXT = 'Aceptado'
export const CANCELLED_STATUS_TEXT = 'Cancelado'

// BusinessStatus
export const BUSINESS_STATUS_REQUESTED_ID = 1
export const BUSINESS_STATUS_LABELLED_ID = 2
export const BUSINESS_STATUS_RECEIVED_ID = 3
export const BUSINESS_STATUS_SENT_ID = 4
export const BUSINESS_STATUS_DELIVERED_ID = 5
export const BUSINESS_STATUS_CANCELLED_ID = 6

export const BUSINESS_STATUS_REQUESTED_TEXT = 'required'
export const BUSINESS_STATUS_LABELLED_TEXT = 'labelled'
export const BUSINESS_STATUS_RECEIVED_TEXT = 'received'
export const BUSINESS_STATUS_SENT_TEXT = 'sent'
export const BUSINESS_STATUS_DELIVERED_TEXT = 'delivered'
export const BUSINESS_STATUS_CANCELLED_TEXT = 'cancelled'

// Referral benefits
export const BENEFIT_SETTING_INVITE_FRIEND_ID = 1
export const REFERRAL_BENEFIT_STATUS_PENDING = 'pending'
export const REFERRAL_BENEFIT_STATUS_ACCEPTED = 'accepted'

//HelpConfiguration
export const VOLUMETRIC_CALCULATOR = 'volumetricCalculator'
