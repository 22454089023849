import '../styles/globals.css'
import 'i18n'
import Head from 'next/head'
import type { AppProps } from 'next/app'
import { NextPage } from 'next'
import { ReactElement, ReactNode } from 'react'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { EmotionCache } from '@emotion/react'
import { createTheme, CssBaseline } from '@mui/material'
// import { RouteGuard } from '@/components/RouteGuard'
import { AuthProvider } from 'context/Auth'
import CookieBanner from '@/components/legal/CookieBanner'
import { useRouter } from 'next/router'

export type NextPageWithLayout = NextPage & {
  // eslint-disable-next-line no-unused-vars
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  emotionCache: EmotionCache
  Component: NextPageWithLayout
}

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: '#0E046D',
    },
    secondary: {
      main: '#424242',
    },
    background: {
      default: '#ffff',
    },
  },
  typography: {
    fontFamily: 'Encode Sans, sans-serif',
    h1: {
      fontSize: '24',
      fontWeight: '800'
    },
    h2: {
      fontSize: 20,
      fontWeight: 'bold',
      marginBottom: 20
    },
    h3: {
      textAlign: 'center',
      fontSize: 20,
      fontWeight: 'bold'
    },
    h4: {
      fontWeight: 'bold',
      fontSize: 16,
      color: '#424242'
    }
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
        margin: 'dense',
        InputLabelProps: {
          style: {
            textTransform: 'uppercase',
            color: '#424242'
          }
        }
      },
    },
    MuiTypography: {
      defaultProps: {
        sx: { textTransform: 'uppercase' }
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        color: 'secondary',
        style: { borderRadius: 0 }
      }
    },
    MuiLink: {
      defaultProps: {
        style: { textDecoration: 'none', color: '#424242' }
      }
    },
    MuiAccordion: {
      defaultProps: {
        style: { boxShadow: 'none' }
      }
    }
  }
})

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const router = useRouter()
  const getLayout = Component.getLayout || ((page: ReactNode) => page) // Use the layout defined at the page level, if available

  return (
    <AuthProvider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          {getLayout(
            <>
              <Head>
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1, user-scalable=0" />
                <title>Syniva web</title>
              </Head>
            {/* <RouteGuard> */}
              <Component {...pageProps} />
            {/* <RouteGuard> */}
            {!router.pathname.includes('/my_account/orders/pay') && <CookieBanner />}

            </>

          )}
        </ThemeProvider>
      </StyledEngineProvider>
    </AuthProvider>
  )
}

export default MyApp
