import { Address } from '@/types/supabase.types'
import { OrderInfoType } from 'pages/shipping_details/[marketplaceId]'

export const validateOrderInfo = (i: OrderInfoType) =>
{
  console.log(i)
  console.log('billing_address valid', validateAddress(i.billing_address))
  console.log('shipping_address valid', validateAddress(i.shipping_address))
  return validateAddress(i.billing_address) && validateAddress(i.shipping_address)
}

export const validateAddress = (a: Address) =>
  a.email &&
  validateEmail(a.email) &&
  a.postal_code &&
  a.name &&
  a.city &&
  // This might cause problems for companies' payments
  (!a.is_company ? a.surnames : true) &&
  a.street &&
  a.phone_number &&
  a.number &&
  a.nif &&
  validateNif(a.nif,a.is_company)

export const validateEmail = (email: string) => {
  return /\S+@\S+\.\S+/.test(email)
}

export const validateNif = (nif: string, isCompany: boolean = false) => {
  if (!nif) return false
  if(isCompany) return true // do not validate company CIFs for now
  let number: string, letter: string, letter2: string
  var regex = /^[XYZ]?\d{5,8}[A-Z]$/

  nif = nif.toUpperCase()

  if (regex.test(nif) === true) {
    number = nif.substr(0, nif.length - 1)
    number = number.replace('X', '0')
    number = number.replace('Y', '1')
    number = number.replace('Z', '2')
    letter2 = nif.substr(nif.length - 1, 1)
    const n = Number.parseInt(number) % 23
    letter = 'TRWAGMYFPDXBNJZSQVHLCKET'
    letter = letter.substring(n, n + 1)
    if (letter != letter2) {
      return false
    } else {
      return true
    }
  } else {
    return false
  }
}
