import { deleteCookie, setCookie } from '@/lib/cookies'
import { getLegal } from '@/lib/supabaseApi'
import { Button, Stack, Switch, Typography, Link, styled } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './CookieBanner.module.css'

export default function CookieBanner() {
  const { t } = useTranslation()
  const [showBanner, setShowBanner] = React.useState(true)
  const [preferences, setPreferences] = React.useState(true)
  const [marketing, setMarketing] = React.useState(true)
  const [statistics, setStatistics] = React.useState(true)
  const [showSwitchGroup, setShowSwitchGroup] = React.useState(false)
  const [legalURLs, setLegalURLs] = React.useState<any>()

  const [showMoreRequired, setShowMoreRequired] = React.useState(false)
  const [showMoreStatistics, setShowMoreStatistics] = React.useState(false)
  const [showMoreMarketing, setShowMoreMarketing] = React.useState(false)

  useEffect(() => {
    getLegal().then((res) => { setLegalURLs(res) })

    if (document.cookie.indexOf('cookieBanner') === -1) {
      setShowBanner(true)
    } else {
      setShowBanner(false)
    }
  }, [])

  const onSwitchGroup = () => {
    setShowSwitchGroup(!showSwitchGroup)
  }
  const onAccept = () => {
    setShowBanner(false)
    setCookie('cookieBanner', 'true', 365)
    setCookie('preferences', 'true', 365)
    setCookie('marketing', 'true', 365)
    setCookie('statistics', 'true', 365)

    deleteCookie('ar_debug', '.doubleclick.net')
    deleteCookie('test_cookie', '.doubleclick.net')  
  }

  const onCancel = () => {
    setShowBanner(false)
    setCookie('cookieBanner', 'true', 365)
    setCookie('preferences', 'false', 365)
    setCookie('marketing', 'false', 365)
    setCookie('statistics', 'false', 365)
    setCookie('statistics', 'false', 365)

    deleteCookie('_ga')
    
    deleteCookie('NID')
    deleteCookie('_gcl_au')
    deleteCookie('_fbp')

    deleteCookie('ar_debug', '.doubleclick.net')
    deleteCookie('test_cookie', '.doubleclick.net')    
  }

  const onSaveCustom = () => {
    setShowBanner(false)
    setCookie('cookieBanner', 'true', 365)
    preferences ? setCookie('preferences', 'true', 365) : setCookie('preferences', 'false', 365)
    marketing ? setCookie('marketing', 'true', 365) : setCookie('marketing', 'false', 365)
    statistics ? setCookie('statistics', 'true', 365) : setCookie('statistics', 'false', 365)

    if (!marketing) {
      deleteCookie('NID')
      deleteCookie('_gcl_au')
      deleteCookie('_fbp')
    }
    if (!statistics) {
      deleteCookie('_ga')
    }

    deleteCookie('ar_debug', '.doubleclick.net')
    deleteCookie('test_cookie', '.doubleclick.net')  
  }

  const handleChangeMarketing = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMarketing(event.target.checked)
  }
  const handleChangeStatistics = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStatistics(event.target.checked)
  }

  const toggleShowMoreRequired = () => {
    setShowMoreRequired(!showMoreRequired)
    setShowMoreStatistics(false)
    setShowMoreMarketing(false)
  }
  const toggleShowMoreStatistics = () => {
    setShowMoreStatistics(!showMoreStatistics)
    setShowMoreRequired(false)
    setShowMoreMarketing(false)
  }
  const toggleShowMoreMarketing = () => {
    setShowMoreMarketing(!showMoreMarketing)
    setShowMoreRequired(false)
    setShowMoreStatistics(false)
  }

  const CustomSwitch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
      borderRadius: 22 / 2,
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 16,
        height: 16,
      },
      '&:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      '&:after': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: 16,
      height: 16,
      margin: 2,
    },
  }))

  return (
    <>
      {showBanner && (
        <div className={styles.banner__dialog}>
          <div className={styles.banner__container}>
            <Stack direction="column" className={styles.cookie__banner__text}>
              <Typography variant="body2" style={{textTransform: 'none'}}>{t('pages.cookies.paragraph')}</Typography>
              <Typography variant="body2" style={{textTransform: 'none'}}>
                {t('pages.cookies.paragraph2')}
                <Link href={legalURLs?.cookies} target="_blank" rel="noreferrer" underline="always" style={{ textTransform: 'none' }}>
                  {t('common.cookies_policy')}.
                </Link>
              </Typography>
            </Stack>
            {showSwitchGroup && (
              <>
                {/* Required Cookies */}
                <div style={{ width: '100%' }}>
                  <div className={styles.banner__switch_group}>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', padding: '0rem 0.5rem 0rem 0.5rem', alignItems: 'center' }}>
                      <FormControlLabel
                        disabled
                        control={<CustomSwitch checked color="default" />}
                        label={t('pages.cookies.necessary')}
                        style={{ width: '100%' }}
                      />
                      <div onClick={toggleShowMoreRequired}>
                        {showMoreRequired ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </div>
                    </div>
                    {(showMoreRequired) && <div style={{ width: '100%' }}>
                      <Typography variant="body2" style={{textTransform: 'none' }}>
                        {t('pages.cookies.requiredCookiesDescription')}
                      </Typography>
                      <div style={{ width: '100%' }}>
                        <ul style={{ paddingLeft: '1rem' }}>
                          <li>
                            <Typography variant="body2" style={{textTransform: 'none' }}><strong>{t('pages.cookies.accessToken')}</strong>:{' '}</Typography>
                            <Typography variant="body2" style={{textTransform: 'none' }}>{t('pages.cookies.accessTokenDescription')}</Typography>
                          </li>
                        </ul>
                      </div>
                      <div style={{ width: '100%' }}>
                        <ul style={{ paddingLeft: '1rem' }}>
                          <li>
                            <Typography variant="body2" style={{textTransform: 'none' }}><strong>{t('pages.cookies.refreshToken')}</strong>:{' '}</Typography>
                            <Typography variant="body2" style={{textTransform: 'none' }}>{t('pages.cookies.refreshTokenDescription')}</Typography>
                          </li>
                        </ul>
                      </div>
                      <div style={{ width: '100%' }}>
                        <ul style={{ paddingLeft: '1rem' }}>
                          <li>
                            <Typography variant="body2" style={{textTransform: 'none' }}><strong>{t('pages.cookies.platform')}</strong>:{' '}</Typography>
                            <Typography variant="body2" style={{textTransform: 'none' }}>{t('pages.cookies.platformDescription')}</Typography>
                          </li>
                        </ul>
                      </div>
                      <div style={{ width: '100%' }}>
                        <ul style={{ paddingLeft: '1rem' }}>
                          <li>
                            <Typography variant="body2" style={{textTransform: 'none' }}><strong>{t('pages.cookies.version')}</strong>:{' '}</Typography>
                            <Typography variant="body2" style={{textTransform: 'none' }}>{t('pages.cookies.versionDescription')}</Typography>
                          </li>
                        </ul>
                      </div>
                      <div style={{ width: '100%' }}>
                        <ul style={{ paddingLeft: '1rem' }}>
                          <li>
                            <Typography variant="body2" style={{textTransform: 'none' }}><strong>{t('pages.cookies.cloudFlare')}</strong>:{' '}</Typography>
                            <Typography variant="body2" style={{textTransform: 'none' }}>{t('pages.cookies.cloudFlareDescription')}</Typography>
                          </li>
                        </ul>
                      </div>
                    </div>}
                  </div>
                </div>
                {/* Statistics Cookies */}
                <div style={{ width: '100%' }}>
                  <div className={styles.banner__switch_group}>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', padding: '0rem 0.5rem 0rem 0.5rem', alignItems: 'center' }}>
                      <FormControlLabel
                        control={<CustomSwitch checked={statistics} color="default" onChange={handleChangeStatistics} />}
                        label={t('pages.cookies.statistics')}
                        style={{ width: '100%' }}
                      />
                      <div onClick={toggleShowMoreStatistics}>
                        {showMoreStatistics ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </div>
                    </div>
                    {(showMoreStatistics) && <div style={{ width: '100%' }}>
                      <Typography variant="body2" style={{textTransform: 'none' }}>
                        {t('pages.cookies.statisticsCookiesDescription')}
                      </Typography>
                      <div style={{ width: '100%' }}>
                        <ul style={{ paddingLeft: '1rem' }}>
                          <li>
                            <Typography variant="body2" style={{textTransform: 'none' }}><strong>{t('pages.cookies.ga')}</strong>:{' '}</Typography>
                            <Typography variant="body2" style={{textTransform: 'none' }}>{t('pages.cookies.gaDescription')}</Typography>
                          </li>
                        </ul>
                      </div>
                    </div>}
                  </div>
                </div>
                {/* Marketing Cookies */}
                <div style={{ width: '100%' }}>
                  <div className={styles.banner__switch_group}>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', padding: '0rem 0.5rem 0rem 0.5rem', alignItems: 'center' }}>
                      <FormControlLabel
                        control={<CustomSwitch checked={marketing} color="default" onChange={handleChangeMarketing} />}
                        label={t('pages.cookies.marketing')}
                        style={{ width: '100%' }}
                      />
                      <div onClick={toggleShowMoreMarketing}>
                        {showMoreMarketing ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </div>
                    </div>
                    {(showMoreMarketing) && <div style={{ width: '100%' }}>
                      <Typography variant="body2" style={{textTransform: 'none' }}>
                        {t('pages.cookies.marketingCookiesDescription')}
                      </Typography>
                      <div style={{ width: '100%' }}>
                        <ul style={{ paddingLeft: '1rem' }}>
                          <li>
                            <Typography variant="body2" style={{textTransform: 'none' }}><strong>{t('pages.cookies.NID')}</strong>:{' '}</Typography>
                            <Typography variant="body2" style={{textTransform: 'none' }}>{t('pages.cookies.NIDDescription')}</Typography>
                          </li>
                        </ul>
                      </div>
                      <div style={{ width: '100%' }}>
                        <ul style={{ paddingLeft: '1rem' }}>
                          <li>
                            <Typography variant="body2" style={{textTransform: 'none' }}><strong>{t('pages.cookies._gcl_au')}</strong>:{' '}</Typography>
                            <Typography variant="body2" style={{textTransform: 'none' }}>{t('pages.cookies._gcl_auDescription')}</Typography>
                          </li>
                        </ul>
                      </div>
                      <div style={{ width: '100%' }}>
                        <ul style={{ paddingLeft: '1rem' }}>
                          <li>
                            <Typography variant="body2" style={{textTransform: 'none' }}><strong>{t('pages.cookies._fbp')}</strong>:{' '}</Typography>
                            <Typography variant="body2" style={{textTransform: 'none' }}>{t('pages.cookies._fbpDescription')}</Typography>
                          </li>
                        </ul>
                      </div>
                    </div>}
                  </div>
                </div>
              </>
            )}
            {(!showSwitchGroup) 
              ? <div className={styles.banner__buttoncolgroup}>
                <Button variant="contained" className={styles.cookie__accept_cookies} onClick={onSwitchGroup}>
                  {t('pages.cookies.config_cookies')}
                </Button>
                <Button variant="contained" className={styles.cookie__accept_cookies} onClick={onCancel}>
                  {t('pages.cookies.reject_cookies')}
                </Button>
                <Button variant="contained" className={styles.cookie__accept_cookies} onClick={onAccept}>
                  {t('pages.cookies.accept_cookies')}
                </Button>
              </div>
              : <div className={styles.banner__buttongroup}>
                <Button variant="contained" className={styles.cookie__accept_cookies} onClick={onSwitchGroup}>
                  {t('pages.cookies.cancel')}
                </Button>
                <Button variant="contained" className={styles.cookie__accept_cookies} onClick={onSaveCustom}>
                  {t('pages.cookies.savePreferences')}
                </Button>
              </div>
            }
          </div>
        </div>
      )}
    </>
  )
}
