import { SupabaseClient } from '@supabase/supabase-js'

const supabaseUrl = process.env.NEXT_PUBLIC_SUPABASE_URL
const supabaseKey = process.env.NEXT_PUBLIC_SUPABASE_KEY
const apiSetCookieUrl = process.env.NEXT_PUBLIC_API_SET_COOKIE_URL || 'https://dev-syniva.es:3000/api/setCookie'
const supabaseClient = new SupabaseClient(supabaseUrl as string, supabaseKey as string)

const setCookie = (access_token: string, refresh_token: string, expires_at: number) => {
  var myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')

  var raw = JSON.stringify({
    'syniva-access-token': access_token,
    'syniva-refresh-token': refresh_token,
    expires: expires_at,
  })

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
  }

  fetch(apiSetCookieUrl, requestOptions)
    .then((response) =>
      response.status === 200
        ? console.log('Cookie setted successfully')
        : console.error(`Cookie setted failed with status ${response.status}`)
    )
    .catch((error) => console.log('error', error))
}

supabaseClient.auth.onAuthStateChange((_event, session) => {
  if (_event === 'SIGNED_OUT') {
    setCookie('KILL', 'KILL', 1)
  } else {
    if (session) {
      setCookie(session.access_token, session.refresh_token || 'null', session.expires_at || 9999999999)
    }
  }
})

export default supabaseClient
