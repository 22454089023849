import { Status } from '@/types/supabase.types'
import { SITES_DOMAINS_EXCLUDES } from './constants'

export const mergeObjectsArraysByKey = (arrayA: any[], arrayB: any[]) => {
  const idMap: any = {}
  arrayA.forEach((data: any) => {
    idMap[data.id] = idMap[data.id] || { id: data.id }
    idMap[data.id] = { ...idMap[data.id], ...data }
  })
  arrayB.forEach((data: any) => {
    idMap[data.id] = idMap[data.id] || { id: data.id }
    idMap[data.id] = { ...idMap[data.id], ...data }
  })
  return Object.keys(idMap).map((id) => idMap[id])
}

export const getStatusTextById = (statusId: number, statusArray: Status[] | null) => {
  if (statusArray) {
    const status = statusArray.filter((row) => row.id === statusId)
    return status[0] ? status[0].name : ''
  }
  return ''
}

export const checkCodeConditions = (codeData: any, orders: any, shippingMethodId: Number, personalData: any, codesRelations: any, usersOrder: any[], user: any, synivaPrice: Number, device: string, marketplaceIdPromo?: number) => {
  // console.log(codeData, orders, shippingMethodId, personalData, codesRelations, usersOrder, user, synivaPrice)
  let returnedValue: any 
  let valid = true
  
  if (!codeData.enabled) {
    valid = false
  }

  const conditions = codeData.value.conditions
  const values = codeData.value.value.split('|')
  const ordersWithCodesPrincipal = orders.filter((x: any) => x.promotional_code === codeData.code) || []
  const ordersWithCodesSecondaryCounts = usersOrder.filter((x: any) => x.promotional_code === codeData.code) || []
  const ordersWithCodes = [...ordersWithCodesPrincipal, ...ordersWithCodesSecondaryCounts]


  const isTemporal = conditions.find((x: any) => x.type && x.type === 'temporal')
  if (isTemporal !== undefined) {
    const { validFrom, validUntil } = isTemporal
    if (validFrom && validUntil) {
      const now = new Date(Date.now()).toISOString()
      const inDateRange = (now >= validFrom && now <= validUntil)
      if (!inDateRange) {
        valid = false
      }
    }
  }

  let isMinimal = false
  let minimalValue = 0
  let badDevice = false
  let deviceError = ''
  let badShipment = false
  let shipmentError = ''

  if (valid && codeData && codeData.enabled && conditions.length > 0 && values.length === 3) {

    if (marketplaceIdPromo && marketplaceIdPromo > 0) {
      const marketCondition = conditions.find((item: any) => item.market && item.market.id)
      if (marketCondition && marketCondition.market && marketCondition.market.id !== marketplaceIdPromo) {
        return { valid: false, reason: { market: false } }
      }
    }

    if (codeData.scope === 'general') {
      conditions.forEach((item: any) => {
        if (item.minimalPrice && item.minimalPrice > 0) {
          if (synivaPrice < item.minimalPrice) {
            valid = false
            isMinimal = true
            minimalValue = item.minimalPrice
          }
        } else if (item.firstOrder && (orders.length > 0 || usersOrder.length > 0)) {
          valid = false
        } else if (item.devices && item.devices !== 'all') {
          if (item.devices === 'onlyWeb' && device !== 'web') {
            valid = false
          } else if (item.devices === 'onlyMobile' && device === 'web') {
            valid = false
          } else if (item.devices === 'onlyAndroid' && device !== 'android') {
            valid = false
          } else if (item.devices === 'onlyIOS' && device !== 'ios') {
            valid = false
          }

          if (!valid) {
            badDevice = true
            deviceError = item.devices
          }

        } else if (item.numberOfUses <= ordersWithCodes.length) {
          valid = false
        } else if (values[0] === 'standardShipment' && shippingMethodId !== 2 && shippingMethodId !== 4) {
          valid = false
          badShipment = true
          shipmentError = 'standardShipment'
        } else if (values[0] === 'onlyStandardShipment' && shippingMethodId !== 2) {
          valid = false
          badShipment = true
          shipmentError = 'onlyStandardShipment'
        } else if (values[0] === 'onlyEconomyShipment' && shippingMethodId !== 4) {
          valid = false
          badShipment = true
          shipmentError = 'onlyEconomyShipment'
        }
      })

      if (valid) {
        const { nif: personalDataNif } = personalData.shipping_address || personalData.billing_address
        codesRelations.forEach((relations: any) => {
          if (relations.nif.toLowerCase() === personalDataNif.toLowerCase() && relations.email !== user?.email) {
            valid = false
          }
        })
      }
    } else if (codeData.scope === 'personal' && codeData.email === user?.email) {
      conditions.forEach((item: any) => {
        if (item.minimalPrice && item.minimalPrice > 0) {
          if (synivaPrice < item.minimalPrice) {
            valid = false
            isMinimal = true
            minimalValue = item.minimalPrice
          }
        } else if (item.devices && item.devices !== 'all') {
          if (item.devices === 'onlyWeb' && device !== 'web') {
            valid = false
          } else if (item.devices === 'onlyMobile' && device === 'web') {
            valid = false
          } else if (item.devices === 'onlyAndroid' && device !== 'android') {
            valid = false
          } else if (item.devices === 'onlyIOS' && device !== 'ios') {
            valid = false
          }

          if (!valid) {
            badDevice = true
            deviceError = item.devices
          }

        } else if (item.firstOrder && (orders.length > 0 || usersOrder.length > 0)) {
          valid = false
        } else if (item.numberOfUses <= ordersWithCodes.length) {
          valid = false
        } else if (item.personal && item.user) {
          const u = item.user
          if ((personalData.shipping_address || personalData.billing_address) && (u.default_shipping_address_id || u.default_billing_address_id)) {
            const { id, nif } = u.default_shipping_address_id || u.default_billing_address_id
            const { nif: personalDataNif } = personalData.shipping_address || personalData.billing_address
            if (nif.toLowerCase() !== personalDataNif.toLowerCase()) {
              valid = false
            }
          }
        } else if (values[0] === 'standardShipment' && shippingMethodId !== 2 && shippingMethodId !== 4) {
          valid = false
          badShipment = true
          shipmentError = 'standardShipment'
        } else if (values[0] === 'onlyStandardShipment' && shippingMethodId !== 2) {
          valid = false
          badShipment = true
          shipmentError = 'onlyStandardShipment'
        } else if (values[0] === 'onlyEconomyShipment' && shippingMethodId !== 4) {
          valid = false
          badShipment = true
          shipmentError = 'onlyEconomyShipment'
        }
      })
    } else if (codeData.scope === 'group') {
      conditions.forEach((item: any) => {
        if (item.minimalPrice && item.minimalPrice > 0) {
          if (synivaPrice < item.minimalPrice) {
            valid = false
            isMinimal = true
            minimalValue = item.minimalPrice
          }
        } else if (item.firstOrder && (orders.length > 0 || usersOrder.length > 0)) {
          valid = false
        } else if (item.devices && item.devices !== 'all') {
          if (item.devices === 'onlyWeb' && device !== 'web') {
            valid = false
          } else if (item.devices === 'onlyMobile' && device === 'web') {
            valid = false
          } else if (item.devices === 'onlyAndroid' && device !== 'android') {
            valid = false
          } else if (item.devices === 'onlyIOS' && device !== 'ios') {
            valid = false
          }

          if (!valid) {
            badDevice = true
            deviceError = item.devices
          }

        } else if (item.group) {
          const u = item.group.find((x: any) => x.email === user?.email) || undefined
          if (u === undefined) {
            valid = false
          } else {
            if ((personalData.shipping_address || personalData.billing_address) && (u.default_shipping_address_id || u.default_billing_address_id)) {
              const { id, nif } = u.default_shipping_address_id || u.default_billing_address_id
              const { nif: personalDataNif } = personalData.shipping_address || personalData.billing_address
              if (nif.toLowerCase() !== personalDataNif.toLowerCase()) {
                valid = false
              }
            }
          }
        } else if (item.numberOfUses <= ordersWithCodes.length) {
          valid = false
        } else if (values[0] === 'standardShipment' && shippingMethodId !== 2 && shippingMethodId !== 4) {
          valid = false
          badShipment = true
          shipmentError = 'standardShipment'
        } else if (values[0] === 'onlyStandardShipment' && shippingMethodId !== 2) {
          valid = false
          badShipment = true
          shipmentError = 'onlyStandardShipment'
        } else if (values[0] === 'onlyEconomyShipment' && shippingMethodId !== 4) {
          valid = false
          badShipment = true
          shipmentError = 'onlyEconomyShipment'
        }
      })
    } else {
      return { valid: false, reason: null }
    }

    if (isMinimal) {
      returnedValue = { valid: false, reason: { minimalValue: minimalValue, minimumError: true } }      
    } else if (badDevice) {
      returnedValue = { valid: false, reason: { deviceError: deviceError } }   
    } else if (badShipment) {
      returnedValue = { valid: false, reason: { shipmentError: shipmentError } }   
    } else {
      returnedValue = { valid: valid, reason: null } 
    }
  }

  return returnedValue
}

export const prepareUrl = (url: string) => {
  try {
    const regex = /(?<=https:\/\/)[^\/]+/
    const site = regex.exec(url);
    if (site !== null && site.length > 0) {
        return site[0]
    } else {
        return url.split('/')[2]
    }
  } catch (err) {
    return url.split('/')[2]
  }
}

export const getOriginalUrl = (url: string) => {
  try {
    const regexOriginalUrl = /([\w+]+\:\/\/)?([\w\d-]+\.)*[\w-]+/
    let originalUrl = regexOriginalUrl.exec(url)
    if (originalUrl !== null && originalUrl.length > 0) {
      return originalUrl[0]
    }
    else {
      const auxUrl = url.split('/')
      return `${auxUrl[0]}//${auxUrl[2]}`
    }
  } catch (err) {
    const auxUrl = url.split('/')
    return `${auxUrl[0]}//${auxUrl[2]}`
  }
  
}

export const prepareItemUrl = (siteName: string) => {
  let result: any
  if (siteName) {
    if (siteName.includes('.')) {
      const siteSplitted = siteName.split('.')
      let sitesWithoutKnownDomains = siteSplitted.map((x: string) => {
        if (!SITES_DOMAINS_EXCLUDES.find((y: string) => y === x)) {
          return x
        } else {
          return null
        }
      })

      sitesWithoutKnownDomains = sitesWithoutKnownDomains.filter((x: string | null) => x !== null)
      result = (sitesWithoutKnownDomains.length === 1) ? sitesWithoutKnownDomains[0] : `${sitesWithoutKnownDomains[0]}.${sitesWithoutKnownDomains[1]}`
    } else {
      if (!SITES_DOMAINS_EXCLUDES.find((y: string) => y === siteName.toLowerCase())) {
        result = siteName
      } else {
        result = ''
      }
    }
  }
  return result || null
}

export const checkInternalShop = (internalShopData: any, siteName: string) => {
  if (internalShopData && internalShopData.length > 0) {
    const scoreShops: any[] = []
    // Score system
    internalShopData.forEach((shop: any) => {
      let score = 0
      // Check complete name
      if (shop.name.toLowerCase() === siteName.toLowerCase()) score += 50

      if (siteName.toLowerCase().includes(shop.name.toLowerCase())) {
        const regexp = new RegExp(`${shop.name.toLowerCase()}`)
        const found = siteName.toLowerCase().match(regexp)
        if (found) score = score + shop.name.length - (shop.name.length - siteName.length)
      }

      scoreShops.push({ shop_id: shop.id, shop_name: shop.name, score: score })
    })

    const minValue = Math.min(scoreShops.map((score: any) => score.score) as any)
    return scoreShops.find((shop: any) => shop.score === minValue)
  } else {
    return null
  }
}

export const priceInCents = (value: number) => {
  return parseInt(String(value * 100))
}

export const sleep = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const excelDateFormat = (datetime: string | undefined): Date | string => {
  if (datetime) {
    const dateObj = new Date(datetime)

    return dateObj
  } else {
    return ''
  }
}
